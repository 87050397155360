import React, { useEffect } from "react";
import Layout from "../../components-pl/layout";
import Form from "../../components-pl/form";
import Link from "gatsby-link";
import SEO from "../../components-pl/seo";
import { StaticImage } from "gatsby-plugin-image";

const ContactPage = () => {
  useEffect(() => {
    document.getElementById("lang-switch").href = "/contact/";
  }, []);

  function Img1() {
    return (
      <StaticImage
        src="../../images/ba1.jpg"
        alt=""
        placeholder="blurred"
        layout="constrained"
        style={{
          gridArea: "1/1",
          borderRadius: "15px",
          maxWidth: "400px",
        }}
        imgClassName="card"
        formats={["auto", "webp", "avif"]}
      />
    );
  }
  return (
    <>
      <SEO
        title={"Kontakt"}
        description={""}
        header={{ siteTitle: " Shroom | Sklep" }}
      />
      <Layout>
        <div
          className="page-container container my-5"
          style={{ textAlign: "center" }}
        >
          <h1 className="title spec-h">Skontaktuj się z nami!</h1>
          <p>Zapraszamy do kontaktu.</p>
          <p> Możesz użyć formularza kontaktowego lub wysłać nam maila!</p>

          <br />
          <div className="row">
            <div className="col card" id="c-form" style={{ minWidth: "200px" }}>
              <Form />
            </div>
            <div className="col" style={{ textAlign: "center" }}>
              <div className="card" style={{ borderRadius: "15px" }}>
                <h2
                  className="mt-5 mb-4 spec-h"
                  style={{
                    textAlign: "center",
                    maxWidth: "80%",
                    marginLeft: "10%",
                  }}
                >
                  Chciałbyś dowiedzieć
                  <br /> się wiecej?
                </h2>
                <h3 style={{ padding: "15px", fontSize: "18px" }}>
                  Śmiało, wyślij nam wiadomość!
                </h3>
                <br />
                <p style={{ textAlign: "center" }} className="mb-2">
                  <a
                    className="btn btn-primary"
                    style={{ color: "white" }}
                    href="mailto:hii@shroom4you.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    hii@shroom4you.com
                  </a>
                </p>
                <p
                  style={{ padding: "15px", maxWidth: "400px", margin: "auto" }}
                >
                  Znalazłeś <b>wadliwą funkcję bądź inną usterkę</b>? Pomóz nam
                  ustalić przyczynę i rozwiązać problem wypełniająć{" "}
                  <b>
                    <a
                      style={{ color: "#0d6efd" }}
                      href="https://docs.google.com/forms/d/e/1FAIpQLSf8SdFhXByXzY2n365L5X_KlUicaxbTL5QmXmMrdzTtR4E40Q/viewform"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      formularz techniczny
                    </a>
                  </b>
                  .
                </p>
                <br />
              </div>
              <br />
              <p style={{ textAlign: "center" }}>
                {" "}
                Chwyć swoje napoje <span className="spec-h">shroom</span> już
                dziś! <br /> <br />
                <Link to="/pl/">{Img1()}</Link>
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default ContactPage;
